import React, { useEffect, useState, useRef } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Textarea from "../components/form/Textarea";
import toastr from "toastr";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DevelopmentReport from "./pdf/DevelopmentReport";
import { useSelector } from "react-redux";
import { NextStepMessage } from "../helpers";
import Image from "../components/elements/Image";
import { postData } from "../services/api";
import { API_URL } from "../config/host";
import ReminderPopup from "../components/popup/ReminderPopup";
import moment from "moment";
import StepAlert from "../components/elements/alert/StepAlert";
import ApiCalendar from "react-google-calendar-api";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import Select from "../components/form/Select";

import { useTranslation } from "react-i18next";

const DevelopmentPlan = () => {
  setTitle("Succession Now | Development Plan");
  const { i18n, t } = useTranslation();
  const wrapperRef = useRef(null);
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [successors, setSuccessors] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isAddField, setIsAddField] = useState(false);
  const [isRemoveField, setIsRemoveField] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [callingFun, setCallingFun] = useState(false);
  const [enabledPopup, setEnabledPopup] = useState(false);
  const [editData, setEditData] = useState({});
  const [actionValue, setActionValue] = useState({});

  const [headings, setHeadings] = useState([]);
  const [predictorHeadings, setPredictorHeadings] = useState([]);
  const [isConnectGoogle, setIsConnectGoogle] = useState(2);
  const [timeFrame, setTimeFrame] = useState([]);

  const tableHeader = [
    {
      name: t("needed_skills_&_abilities"),
      option: t("needed_skills_content"),
      isReminder: false,
    },
    {
      name: t("needed_experience_or_seasoning"),
      option: t("needed_experience_content"),
      isReminder: false,
    },
    {
      name: t("needed_education"),
      option: t("needed_education_content"),
      isReminder: false,
    },
  ];

  const heading = [
    {
      name: t("needed_skills_&_abilities"),
      option: t("needed_skills_content"),
      isReminder: false,
    },
    {
      name: t("timeframe"),
      option: "",
      isReminder: true,
    },
    {
      name: t("needed_experience_or_seasoning"),
      option: t("needed_experience_content"),
      isReminder: false,
    },
    {
      name: t("timeframe"),
      option: "",
      isReminder: true,
    },
    {
      name: t("needed_education"),
      option: t("needed_education_content"),
      isReminder: false,
    },
    {
      name: t("timeframe"),
      option: "",
      isReminder: true,
    },
    {
      name: t("areas_of_concern"),
      option: "",
      isReminder: false,
    },
    {
      name: t("missing_information"),
      option: "",
      isReminder: false,
    },
  ];

  const config = {
    clientId:
      "889893139103-m7j23p0ill29ridmh7ogola48skavtqv.apps.googleusercontent.com",
    apiKey: "AIzaSyCO3kPKuzy5Zg5jv6ZHbqwvNgxQDytK2Ys",
    scope:
      "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
    offline: true,
  };

  const apiCalendar = new ApiCalendar(config);

  useEffect(() => {
    async function headingData() {
      try {
        const result = await postData("heading/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "concern",
        });
        if (result.data) {
          setHeadings(result.data);
        } else {
          console.log("Concern heading list message", result.message);
        }
      } catch (error) {
        console.log("Error concern heading list catch", error.message);
      }
    }
    headingData();

    async function predictorHeadingData() {
      try {
        const result = await postData("heading/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "predictors",
        });
        if (result.data) {
          setPredictorHeadings(result.data);
        } else {
          console.log("Predictor heading list message", result.message);
        }
      } catch (error) {
        console.log("Error predictor heading list catch", error.message);
      }
    }
    predictorHeadingData();

    async function timeFrameData() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type : "development-timeframe"
        });
        if (result.data) {
          setTimeFrame(result.data);
        } else {
          console.log("Time Frame list message", result.message);
        }
      } catch (error) {
        console.log("Error time frame list catch", error.message);
      }
    }
    timeFrameData();
  }, []);

  useEffect(() => {
    // Successor List
    const loadSuccessors = async () => {
      setLoading(true);
      try {
        const result = await postData("successor/list", {
          sortQuery: { created_at: 1 },
          is_interim: 2,
        });
        if (result.data) {
          setSuccessors(
            result.data.map((value, key) => {
              return {
                ...value,
                plan_skills:
                  value?.plan_skills && value?.plan_skills.length > 0
                    ? value?.plan_skills
                    : [""],
                plan_experiences:
                  value?.plan_experiences && value?.plan_experiences.length > 0
                    ? value?.plan_experiences
                    : [""],
                plan_educations:
                  value?.plan_educations && value?.plan_educations.length > 0
                    ? value?.plan_educations
                    : [""],
                plan_skills_timeframe:
                  value?.plan_skills_timeframe &&
                  value?.plan_skills_timeframe.length > 0
                    ? value?.plan_skills_timeframe
                    : null,
                plan_experiences_timeframe:
                  value?.plan_experiences_timeframe &&
                  value?.plan_experiences_timeframe.length > 0
                    ? value?.plan_experiences_timeframe
                    : null,
                plan_educations_timeframe:
                  value?.plan_educations_timeframe &&
                  value?.plan_educations_timeframe.length > 0
                    ? value?.plan_educations_timeframe
                    : null,
              };
            })
          );
        }
        //console.log(successors)
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadSuccessors();
  }, [actionValue || ""]);

  const addRow = async (id, index, field, position) => {
    setIsAddField(true);
    if (field === "skills") {
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_skills: [...it?.plan_skills, ""],
            };
          } else {
            return it;
          }
        })
      );
    } else if (field === "experience") {
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_experiences: [...it?.plan_experiences, ""],
            };
          } else {
            return it;
          }
        })
      );
    } else if (field === "education") {
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_educations: [...it?.plan_educations, ""],
            };
          } else {
            return it;
          }
        })
      );
    } else if (field === "timeframe") {
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_timeframe: it?.plan_timeframe,
            };
          } else {
            return it;
          }
        })
      );
    }
    setIsAddField(false);
  };

  const removeRow = async (id, index, field, position) => {
    if (field === "skills") {
      successors[index].plan_skills.splice(position, 1);
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_skills: [...it?.plan_skills],
            };
          } else {
            return it;
          }
        })
      );
    } else if (field === "experience") {
      successors[index].plan_experiences.splice(position, 1);
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_experiences: [...it?.plan_experiences],
            };
          } else {
            return it;
          }
        })
      );
    } else if (field === "education") {
      successors[index].plan_educations.splice(position, 1);
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_educations: [...it?.plan_educations],
            };
          } else {
            return it;
          }
        })
      );
    } else if (field === "timeframe") {
      successors[index].plan_timeframe.splice(position, 1);
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_timeframe: it?.plan_timeframe,
            };
          } else {
            return it;
          }
        })
      );
    }
  };

  const handelUpdate = async (id, index, value, field, position) => {
    setCallingFun(false);
    if (field === "skills") {
      successors[index].plan_skills[position] = value;
      //console.log(successors)
      setSuccessors((successors) =>
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_skills: [...it?.plan_skills],
            };
          } else {
            return it;
          }
        })
      );
    } else if (field === "experience") {
      successors[index].plan_experiences[position] = value;
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_experiences: [...it?.plan_experiences],
            };
          } else {
            return it;
          }
        })
      );
    } else if (field === "education") {
      successors[index].plan_educations[position] = value;
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              plan_educations: [...it?.plan_educations],
            };
          } else {
            return it;
          }
        })
      );
    } else if (field === "plan_skills_timeframe") {
      successors[index].plan_skills_timeframe = value;
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              //plan_skills_timeframe: [...it?.plan_skills_timeframe],
              plan_skills_timeframe: it?.plan_skills_timeframe,
            };
          } else {
            return it;
          }
        })
      );
      setCallingFun(true);
    } else if (field === "plan_experiences_timeframe") {
      successors[index].plan_experiences_timeframe = value;
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              //plan_experiences_timeframe: [...it?.plan_experiences_timeframe],
              plan_experiences_timeframe: it?.plan_experiences_timeframe,
            };
          } else {
            return it;
          }
        })
      );
      setCallingFun(true);
    } else if (field === "plan_educations_timeframe") {
      successors[index].plan_educations_timeframe = value;
      setSuccessors(
        successors.map((it, idx) => {
          if (idx === index) {
            return {
              ...it,
              //plan_educations_timeframe: [...it?.plan_educations_timeframe],
              plan_educations_timeframe: it?.plan_educations_timeframe,
            };
          } else {
            return it;
          }
        })
      );
      setCallingFun(true);
    }
  };

  useEffect(() => {
    const onUpdate = async (e) => {
      setIsUpdate(true);
      //console.log(successors)
      try {
        const result = await postData("development-plan/update", {
          successors: successors,
        });
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setCallingFun(false);
          setIsUpdate(false);
          await postData("download-succession-plan/close-approval", {});
        } else {
          toastr.error(result.message);
          setCallingFun(false);
          setIsUpdate(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setCallingFun(false);
        setIsUpdate(false);
      }
    };
    if (callingFun) {
      onUpdate();
    }
  }, [callingFun]);

  // const onCancel = async (e) => {
  //   setIsCancel(true);
  //   setIsCancel(false);
  // }

  useEffect(() => {
    // if (successors && successors.length > 0) {
    //   let planSkillsRecord = successors
    //     .filter((item) => item.plan_skills)
    //     .map((item) => item.plan_skills.filter((row) => !row));
    //   let planSkillsEmptyRecord = planSkillsRecord.filter((item) => !item);
    //   let planExperiences = successors
    //     .filter((item) => item.plan_experiences)
    //     .map((item) => item.plan_experiences.filter((row) => !row));
    //   let planExperienceEmptyRecord = planExperiences.filter((item) => !item);
    //   let planEducations = successors
    //     .filter((item) => item.plan_educations)
    //     .map((item) => item.plan_educations.filter((row) => !row));
    //   let planEducationRecord = planEducations.filter((item) => !item);
    //   let planSkillsTimeframe = successors.filter(
    //     (item) => !item.plan_skills_timeframe
    //   );
    //   let planExperiencesTimeframe = successors.filter(
    //     (item) => !item.plan_experiences_timeframe
    //   );
    //   let planEducationsTimeframe = successors.filter(
    //     (item) => !item.plan_educations_timeframe
    //   );
    //   if (
    //     planSkillsEmptyRecord &&
    //     planSkillsEmptyRecord.length === 0 &&
    //     planExperienceEmptyRecord &&
    //     planExperienceEmptyRecord.length === 0 &&
    //     planEducationRecord &&
    //     planEducationRecord.length === 0 &&
    //     planSkillsTimeframe.length === 0 &&
    //     planExperiencesTimeframe.length === 0 &&
    //     planEducationsTimeframe.length === 0
    //   ) {
    //     setIsNextStep(true);
    //   } else {
    //     setIsNextStep(false);
    //   }
    // } else {
    //   setIsNextStep(false);
    // }
    setIsNextStep(true);
  }, [successors]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("development_plan")}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          <Button
            buttonClasses={isNextStep ? "" : "!bg-rose-500"}
            buttonType={"button"}
            buttonIcon={"fa-light fa-arrow-right-to-arc"}
            buttonIconPosition={"left"}
            buttonLabel={t("next_step")}
            buttonHasLink={isNextStep ? true : false}
            buttonLink={"/advancement-readiness"}
            functions={() => toastr.error(NextStepMessage)}
          />
        </div>
      </div>
      <StepAlert />
      <div className="w-full bg-white rounded-md shadow overflow-hidden mt-3">
        <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow min-h-[480px]">          
          {
            (totalDataCount > 0) ? (
              successors.map((item, index) => {
                let mejorConcern = [];
                if (item?.concerns) {
                  headings.map((ph_item, index) => {
                    let h_idx = item?.concerns.findIndex(
                      (p) => p.heading_id === ph_item?._id
                    );
                    let opt_id = item?.concerns[h_idx]?.option_id;
                    let op_idx = ph_item?.options.findIndex(
                      (op) => op._id === opt_id
                    );
                    mejorConcern.push(
                      index === h_idx &&
                        ph_item?.options[op_idx]?._id === opt_id
                        ? {
                            pass: ph_item?.options[op_idx]?.isPass,
                            name: ph_item?.name,
                          }
                        : ""
                    );
                  });
                }
                let result = mejorConcern.filter((item) => !item.pass);

                let mejorPredictor = [];
                if (item?.predictors) {
                  predictorHeadings.map((ph_item, index) => {
                    let h_idx = item?.predictors.findIndex(
                      (p) => p.heading_id === ph_item?._id
                    );
                    let opt_id = item?.predictors[h_idx]?.option_id;
                    let op_idx = ph_item?.options.findIndex(
                      (op) => op._id === opt_id
                    );
                    mejorPredictor.push(
                      index === h_idx &&
                        ph_item?.options[op_idx]?._id === opt_id
                        ? {
                            pass: ph_item?.options[op_idx]?.isPass,
                            name: ph_item?.name,
                          }
                        : ""
                    );
                  });
                }
                let result2 = mejorPredictor.filter((item) => !item.pass);
                return (
                  <table className="min-w-max w-full table-auto" key={item._id}>
                    <thead key={item._id}>
                      <tr className="text-white font-bold text-sm leading-none border-b border-slate-200" key={item._id}>
                        <th className="py-6 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">
                          {t("current_needs")}
                        </th>
                        {tableHeader.length > 0 &&
                          tableHeader.map((it, idx) => {
                            return (
                              <th
                                className={
                                  "py-6 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900"
                                }
                              >
                                {it?.name}
                              </th>
                            );
                          })}
                      </tr>
                    </thead>
                    <tbody className="text-slate-700 text-sm font-light" key={item._id}>
                      <tr className="border-b border-slate-200" key={item._id}>
                        <td className="py-3 px-2 text-left max-w-[240px] align-top">
                          <div className="block">
                            <div className="text-sm font-medium text-slate-600">
                              {item?.name}
                            </div>
                            <div className="text-xs text-slate-400">
                              {item?.position_details
                                ? item?.position_details[0]?.short_name
                                : "N/A"}
                            </div>
                          </div>
                          <div className="">
                            {
                              (result.length > 0 || result2.length > 0) && 
                              <div className="space-y-2 bg-slate-50 rounded-lg py-4 px-4 mt-5">
                                <span className="text-sm font-medium text-slate-700 block py-1 border-b border-slate-300">
                                  {" "}
                                  {t("areas_of_concern")}{" "}
                                </span>
                                <div className="space-y-0.5">
                                  <div className="w-full ">
                                    {result &&
                                      result.length > 0 &&
                                      mejorConcern.length > 0 &&
                                      mejorConcern.map((itm) => {
                                        if (!itm?.pass) {
                                          return (
                                            <span className="text-sm font-semibold text-red-600 block">
                                              {itm?.name}
                                            </span>
                                          );
                                        }
                                      })}
                                  </div>
                                  <div className="w-full">
                                    {result2 &&
                                      result2.length > 0 &&
                                      mejorPredictor.length > 0 &&
                                      mejorPredictor.map((itm) => {
                                        if (!itm?.pass) {
                                          return (
                                            <span className="text-sm font-semibold text-red-600 block py-1">
                                              {itm?.name}
                                            </span>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                            }
                            {
                              ((item?.loyalty_details.length > 0 && item?.loyalty_details[0]?.isClass ===
                                "blue") || (item?.desire_details.length > 0 && item?.desire_details[0]?.isClass ===
                                  "blue") || (item?.retirement_details.length > 0 && item?.retirement_details[0]?.isClass ===
                                    "blue")) &&
                              <div className="space-y-2 bg-slate-50 rounded-lg py-4 px-4 mt-5">
                                <span className="text-sm font-medium text-slate-700 block py-1 border-b border-slate-300">
                                  {" "}
                                  {t("missing_information")}{" "}
                                </span>
                                <div className="space-y-0.5">
                                  <div className="w-full">
                                    {item?.loyalty_details &&
                                    item?.loyalty_details.length > 0 ? (
                                      item?.loyalty_details[0]?.isClass ===
                                      "blue" ? (
                                        <span className="text-sm font-semibold text-blue-600 block">
                                          {t("unknow_loyalty")}
                                        </span>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {item?.desire_details &&
                                    item?.desire_details.length > 0 ? (
                                      item?.desire_details[0]?.isClass ===
                                      "blue" ? (
                                        <span className="text-sm font-semibold text-blue-600 block">
                                          {t("unknown_desire_to_advance")}
                                        </span>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {item?.retirement_details &&
                                    item?.retirement_details.length > 0 ? (
                                      item?.retirement_details[0]?.isClass ===
                                      "blue" ? (
                                        <span className="text-sm font-semibold text-blue-600 block">
                                          {t("unknown_retirement_window")}
                                        </span>
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            }                            
                          </div>
                        </td>
                        <td className="py-3 px-2 text-left max-w-[240px] align-top">
                          <div className="space-y-2">
                            {item?.plan_skills &&
                              item?.plan_skills.map((row, key) => {
                                return (
                                  <div className="flex gap-2 w-full" key={key}>
                                    <div className="w-full">
                                      <Textarea
                                        inputValue={row}
                                        inputClasses={"!h-40"}
                                        //errorType={'error'}
                                        setTextarea={(val) => handelUpdate(item._id,index,val,"skills",key)}
                                        onBlur={(val) => { setCallingFun(true) }}
                                      />
                                    </div>
                                    {key === 0 ? (
                                      <Button
                                        buttonIcon={
                                          isAddField
                                            ? "fa-light fa-spinner fa-spin"
                                            : "fa-light fa-plus"
                                        }
                                        buttonIconPosition={"left"}
                                        buttonClasses={
                                          "!h-6 !w-6 !min-w-[24px] !text-sm !p-0"
                                        }
                                        buttonType={"button"}
                                        functions={(e) =>
                                          addRow(item._id, index, "skills", key)
                                        }
                                        buttonHasLink={false}
                                        buttonDisabled={isAddField}
                                      />
                                    ) : (
                                      <Button
                                        buttonIcon={
                                          isRemoveField
                                            ? "fa-light fa-spinner fa-spin"
                                            : "fa-light fa-minus"
                                        }
                                        buttonIconPosition={"left"}
                                        buttonClasses={
                                          "!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500"
                                        }
                                        buttonType={"button"}
                                        functions={(e) =>
                                          removeRow(
                                            item._id,
                                            index,
                                            "skills",
                                            key
                                          )
                                        }
                                        buttonHasLink={false}
                                        buttonDisabled={isRemoveField}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                          {/* Timeframe */}
                          <div className="space-y-2 bg-slate-50 rounded-lg py-4 px-4 mt-5">
                            <span className="text-sm font-medium text-slate-700">
                              {" "}
                              {t("timeframe")}{" "}
                            </span>
                            <div className="flex gap-2 w-full">
                              <div className="w-full">                                     
                                <Select
                                  xPlacement={"left"}
                                  selectedValue={item?.plan_skills_timeframe}
                                  dropdownClass={"!w-60"}
                                  dropdownButtonLabelClass={"!font-bold"}
                                  dropdownData={[
                                    { _id: "", label: t('select'), value: "" },
                                    ...timeFrame.map((frame_item) => ({
                                      _id: frame_item?._id,
                                      label: frame_item?.name,
                                      value: frame_item?._id,
                                    })),
                                  ]}
                                  dropdownOptionBold={true}
                                  getSelectedValue={(e) =>
                                    handelUpdate(
                                      item._id,
                                      index,
                                      e._id,
                                      "plan_skills_timeframe"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex items-center space-x-2">
                              <Button
                                buttonType={"button"}
                                buttonClasses={
                                  "!p-0 !flex !items-center !justify-center !text-sm !bg-transparent !text-teal-600 !h-5"
                                }
                                buttonIcon={"fa-regular fa-calendar-days"}
                                buttonIconPosition={"right"}
                                buttonLabel={t("reminder")}
                                buttonHasLink={false}
                                functions={() => {
                                  setEnabledPopup(true);
                                  setEditData({
                                    _id: item._id,
                                    name: t("timeframe"),
                                    filed: "plan_skills_timeframe_reminder",
                                  });
                                }}
                              />
                              {item && item?.plan_skills_timeframe_reminder ? (
                                <span className="text-sm font-semibold">
                                  {moment(
                                    new Date(item?.plan_skills_timeframe_reminder)
                                  ).format("MM/DD/YYYY")}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="py-3 px-2 text-left max-w-[240px] align-top">
                          <div className="space-y-2">
                            {item?.plan_experiences &&
                              item?.plan_experiences.map((row2, key2) => {
                                return (
                                  <div className="flex gap-2 w-full" key={key2}>
                                    <div className="w-full">
                                      <Textarea
                                        inputValue={row2}
                                        inputClasses={"!h-40"}
                                        //errorType={'error'}
                                        setTextarea={(val) =>
                                          handelUpdate(
                                            item._id,
                                            index,
                                            val,
                                            "experience",
                                            key2
                                          )
                                        }
                                        onBlur={(val) =>  setCallingFun(true)}
                                      />
                                    </div>
                                    {key2 === 0 ? (
                                      <Button
                                        buttonIcon={
                                          isAddField
                                            ? "fa-light fa-spinner fa-spin"
                                            : "fa-light fa-plus"
                                        }
                                        buttonIconPosition={"left"}
                                        buttonClasses={
                                          "!h-6 !w-6 !min-w-[24px] !text-sm !p-0"
                                        }
                                        buttonType={"button"}
                                        functions={(e) =>
                                          addRow(
                                            item._id,
                                            index,
                                            "experience",
                                            key2
                                          )
                                        }
                                        buttonHasLink={false}
                                        buttonDisabled={isAddField}
                                      />
                                    ) : (
                                      <Button
                                        buttonIcon={
                                          isRemoveField
                                            ? "fa-light fa-spinner fa-spin"
                                            : "fa-light fa-minus"
                                        }
                                        buttonIconPosition={"left"}
                                        buttonClasses={
                                          "!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500"
                                        }
                                        buttonType={"button"}
                                        functions={(e) =>
                                          removeRow(
                                            item._id,
                                            index,
                                            "experience",
                                            key2
                                          )
                                        }
                                        buttonHasLink={false}
                                        buttonDisabled={isRemoveField}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                          {/* Timeframe */}
                          <div className="space-y-2 bg-slate-50 rounded-lg py-4 px-4 mt-5">
                            <span className="text-sm font-medium text-slate-700"> {t("timeframe")} </span>
                            <div className="flex gap-2 w-full">
                              <div className="w-full">                                        
                                <Select
                                  xPlacement={"left"}
                                  selectedValue={item?.plan_experiences_timeframe}
                                  dropdownClass={"!w-60"}
                                  dropdownButtonLabelClass={"!font-bold"}
                                  dropdownData={[
                                    { _id: "", label: t('select'), value: "" },
                                    ...timeFrame.map((frame_item) => ({
                                      _id: frame_item?._id,
                                      label: frame_item?.name,
                                      value: frame_item?._id,
                                    })),
                                  ]}
                                  dropdownOptionBold={true}
                                  getSelectedValue={(e) =>
                                    handelUpdate(
                                      item._id,
                                      index,
                                      e._id,
                                      "plan_experiences_timeframe"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex items-center space-x-2">
                              <Button
                                buttonType={"button"}
                                buttonClasses={
                                  "!p-0 !flex !items-center !justify-center !text-sm !bg-transparent !text-teal-600 !h-5"
                                }
                                buttonIcon={
                                  "fa-regular fa-calendar-days"
                                }
                                buttonIconPosition={"right"}
                                buttonLabel={t("reminder")}
                                buttonHasLink={false}
                                functions={() => {
                                  setEnabledPopup(true);
                                  setEditData({
                                    _id: item._id,
                                    name: t("timeframe"),
                                    filed:
                                      "plan_experiences_timeframe_reminder",
                                  });
                                }}
                              />
                              {item &&
                              item?.plan_experiences_timeframe_reminder ? (
                                <span className="text-sm font-semibold">
                                  {moment(
                                    new Date(
                                      item?.plan_experiences_timeframe_reminder
                                    )
                                  ).format("MM/DD/YYYY")}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>                        
                        </td>
                        <td className="py-3 px-2 text-left max-w-[240px] align-top">
                          <div className="space-y-2">
                            {item?.plan_educations &&
                              item?.plan_educations.map((row3, key3) => {
                                return (
                                  <div className="flex gap-2 w-full" key={key3}>
                                    <div className="w-full">
                                      <Textarea
                                        inputValue={row3}
                                        inputClasses={"!h-40"}
                                        //errorType={'error'}
                                        setTextarea={(val) =>
                                          handelUpdate(
                                            item._id,
                                            index,
                                            val,
                                            "education",
                                            key3
                                          )
                                        }
                                        onBlur={(val) =>  setCallingFun(true)}
                                      />
                                    </div>
                                    {key3 === 0 ? (
                                      <Button
                                        buttonIcon={
                                          isAddField
                                            ? "fa-light fa-spinner fa-spin"
                                            : "fa-light fa-plus"
                                        }
                                        buttonIconPosition={"left"}
                                        buttonClasses={
                                          "!h-6 !w-6 !min-w-[24px] !text-sm !p-0"
                                        }
                                        buttonType={"button"}
                                        functions={(e) =>
                                          addRow(
                                            item._id,
                                            index,
                                            "education",
                                            key3
                                          )
                                        }
                                        buttonHasLink={false}
                                        buttonDisabled={isAddField}
                                      />
                                    ) : (
                                      <Button
                                        buttonIcon={
                                          isRemoveField
                                            ? "fa-light fa-spinner fa-spin"
                                            : "fa-light fa-minus"
                                        }
                                        buttonIconPosition={"left"}
                                        buttonClasses={
                                          "!h-6 !w-6 !min-w-[24px] !text-sm !p-0 !bg-rose-500"
                                        }
                                        buttonType={"button"}
                                        functions={(e) =>
                                          removeRow(
                                            item._id,
                                            index,
                                            "education",
                                            key3
                                          )
                                        }
                                        buttonHasLink={false}
                                        buttonDisabled={isRemoveField}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                          {/* Timeframe */}
                          <div className="space-y-2 bg-slate-50 rounded-lg py-4 px-4 mt-5">
                            <span className="text-sm font-medium text-slate-700"> {t("timeframe")} </span>
                            <div className="flex gap-2 w-full">
                              <div className="w-full">                                        
                                <Select
                                  xPlacement={"left"}
                                  selectedValue={item?.plan_educations_timeframe}
                                  dropdownClass={"!w-60"}
                                  dropdownButtonLabelClass={"!font-bold"}
                                  dropdownData={[
                                    { _id: "", label: t('select'), value: "" },
                                    ...timeFrame.map((frame_item) => ({
                                      _id: frame_item?._id,
                                      label: frame_item?.name,
                                      value: frame_item?._id,
                                    })),
                                  ]}
                                  dropdownOptionBold={true}
                                  getSelectedValue={(e) =>
                                    handelUpdate(
                                      item._id,
                                      index,
                                      e._id,
                                      "plan_educations_timeframe"
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex items-center space-x-2">
                              <Button
                                buttonType={"button"}
                                buttonClasses={
                                  "!p-0 !flex !items-center !justify-center !text-sm !bg-transparent !text-teal-600  !h-5"
                                }
                                buttonIcon={
                                  "fa-regular fa-calendar-days"
                                }
                                buttonIconPosition={"right"}
                                buttonLabel={t("reminder")}
                                buttonHasLink={false}
                                functions={() => {
                                  setEnabledPopup(true);
                                  setEditData({
                                    _id: item._id,
                                    name: t("timeframe"),
                                    filed:
                                      "plan_educations_timeframe_reminder",
                                  });
                                }}
                              />
                              {item &&
                              item?.plan_educations_timeframe_reminder ? (
                                <span className="text-sm font-semibold">
                                  {moment(
                                    new Date(
                                      item?.plan_educations_timeframe_reminder
                                    )
                                  ).format("MM/DD/YYYY")}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                      {/* <tr className="border-b border-slate-200" key={item._id}>
                        <td className="py-3 px-2 text-left max-w-[240px] align-top">
                          <div className="block">
                            <div className="text-sm font-medium text-slate-600">
                              {t("areas_of_concern")}
                            </div>
                          </div>
                        </td>
                        <td className="py-3 px-2 text-left max-w-[240px] align-top">
                          <div className="space-y-0.5">
                            <div className="w-full ">
                              {result &&
                                result.length > 0 &&
                                mejorConcern.length > 0 &&
                                mejorConcern.map((itm) => {
                                  if (!itm?.pass) {
                                    return (
                                      <span className="text-sm font-semibold text-red-600 block">
                                        {itm?.name}
                                      </span>
                                    );
                                  }
                                })}
                            </div>
                            <div className="w-full">
                              {result2 &&
                                result2.length > 0 &&
                                mejorPredictor.length > 0 &&
                                mejorPredictor.map((itm) => {
                                  if (!itm?.pass) {
                                    return (
                                      <span className="text-sm font-semibold text-red-600 block py-1">
                                        {itm?.name}
                                      </span>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-slate-200" key={item._id}>
                        <td className="py-3 px-2 text-left max-w-[240px] align-top">
                          <div className="block">
                            <div className="text-sm font-medium text-slate-600">
                              {t("missing_information")}
                            </div>
                          </div>
                        </td>
                        <td className="py-3 px-2 text-left max-w-[240px] align-top">
                          <div className="space-y-0.5">
                            <div className="w-full">

                              {item?.loyalty_details &&
                              item?.loyalty_details.length > 0 ? (
                                item?.loyalty_details[0]?.isClass ===
                                "blue" ? (
                                  <span className="text-sm font-semibold text-blue-600 block">
                                    {t("unknow_loyalty")}
                                  </span>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {item?.desire_details &&
                              item?.desire_details.length > 0 ? (
                                item?.desire_details[0]?.isClass ===
                                "blue" ? (
                                  <span className="text-sm font-semibold text-blue-600 block">
                                    {t("unknown_desire_to_advance")}
                                  </span>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              {item?.retirement_details &&
                              item?.retirement_details.length > 0 ? (
                                item?.retirement_details[0]?.isClass ===
                                "blue" ? (
                                  <span className="text-sm font-semibold text-blue-600 block">
                                    {t("unknown_retirement_window")}
                                  </span>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                )
              })
            ) : (
              <table className="min-w-max w-full table-auto">
                <tr className="border-b border-slate-200">
                  <td className="py-3 px-2 text-left" colSpan={20}>
                    {loading ? (
                      <Searching label={"Searching"} />
                    ) : (
                      <NoDataFound label={"No Data Found"} />
                    )}
                  </td>
                </tr>
              </table>
            )
          }          
        </div>

        {/* <div className="py-3 px-3">
          <div className="flex items-center justify-end gap-4">            
            <Button 
            buttonType={'button'} 
            buttonIcon={(isUpdate)?'fa-light fa-spinner fa-spin':'fa-light fa-check'} 
            buttonIconPosition={'left'} 
            buttonLabel={'Save'} 
            functions={onUpdate}
            buttonHasLink={false}
            buttonDisabled={isUpdate}
            />
          </div>
        </div> */}
      </div>
      
      {enabledPopup && (
        <ReminderPopup
          isOpen={enabledPopup}
          setIsOpen={(val) => setEnabledPopup(val)}
          data={editData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></ReminderPopup>
      )}
    </>
  );
};

export default DevelopmentPlan;
