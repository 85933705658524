import React, { useState, useRef, useEffect } from "react";

import { allowedImageExtensions } from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import { postData } from "../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import ButtonFile from "../../components/form/ButtonFile";
import Image from "../../components/elements/Image";

import { useTranslation } from "react-i18next";

const AccountCreation = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Company");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | New Account Creation");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [plans, setPlans] = useState([]);
  const [staff, setStaff] = useState([]);
  const [sales, setSales] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [appointmentSetter, setAppointmentSetter] = useState([]);
  const [salesAdmin, setalesAdmin] = useState([]);
  const [isManage, setIsManage] = useState(false);
  const [name, setName] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [number_of_employees, setNumberOfEmployees] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [promoCode, setPrromoCode] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [price, setPrice] = useState("");
  //const [staffId,setStaffId] = useState('');
  const [salesId, setSalesId] = useState("");
  const [consultantId, setConsultantId] = useState("");
  const [appointmentSetterId, setAppointmentSetterId] = useState("");
  const [salesAdminId, setSalesAdminId] = useState("");
  const [salesCommission, setSalesCommission] = useState(0);
  const [consultantCommission, setConsultantCommission] = useState(0);
  const [appointmentSetterCommission, setAppointmentSetterCommission] = useState(0);
  const [salesAdminCommission, setSalesAdminCommission] = useState(0);
  const [showLogo, setShowLogo] = useState("");
  const [image, setImage] = useState("");
  const [accountType, setAccountType] = useState("");

  const staffArray = [
    {
      code: "sales-staff",
      role_id: null,
      staff_id: salesId ? salesId : null,
      commission: salesCommission,
    },
    {
      code: "consultant",
      role_id: null,
      staff_id: consultantId ? consultantId : null,
      commission: consultantCommission,
    },
    {
      code: "appointment-setter",
      role_id: null,
      staff_id: appointmentSetterId ? appointmentSetterId : null,
      commission: appointmentSetterCommission,
    },
    {
      code: "sales-admin",
      role_id: null,
      staff_id: salesAdminId ? salesAdminId : null,
      commission: salesAdminCommission,
    },
  ];

  const capitalizeString = (str) => {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  };

  useEffect(() => {
    // Plan list
    const loadPlans = async () => {
      try {
        const result = await postData("plans/list", {
          sortQuery: { ordering: 1 },
        });
        if (result.data) {
          setPlans(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadPlans();

    const loadStaffes = async () => {
      try {
        const result = await postData("usertype/list", {
          sortQuery: { ordering: 1 },
          isStaff: 1,
          status: 1,
        });
        if (result.status === 200) {
          let sales = [];
          let consultants = [];
          let appointmentSetter = [];
          let salesAdmin = [];
          result.data.map((value) => {
            sales.push(value);
            consultants.push(value);
            appointmentSetter.push(value);
            salesAdmin.push(value);
          });
          setSales(sales);
          setConsultants(consultants);
          setAppointmentSetter(appointmentSetter);
          setalesAdmin(salesAdmin);
          setStaff(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadStaffes();
  }, []);

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];
      if (![...allowedImageExtensions()].includes(ext)) {
        toastr.error("Please upload a valid logo");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        setImage(e.target.files[0]);
      }
    }
  };

  const onSubmit = async (e) => {
    setIsManage(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("company_name", company_name);
    formData.append("number_of_employees", number_of_employees);
    formData.append("code", "customer-admin");
    formData.append("payment_status", paymentStatus);
    formData.append("account_type", accountType);
    staffArray.forEach((obj, index) => {
      const objString = JSON.stringify(obj);
      formData.append(`staffs[${index}]`, objString);
    });
    formData.append("price", price);    
    formData.append("sales_id", salesId ? salesId : null);
    formData.append("consultant_id", consultantId ? consultantId : null);
    formData.append("appointment_setter_id", appointmentSetterId ? appointmentSetterId : null);
    formData.append("image", image);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "usertype/update";
        formData.append("id", id);
        payload = formData;
        // {
        //   id: id,
        //   name: name,
        //   email: email,
        //   company_name: company_name,
        //   number_of_employees: number_of_employees,
        //   code: 'customer-admin',
        //   payment_status: paymentStatus,
        //   staffs:staffArray,
        //   price:price,
        //   sales_id : (salesId)?salesId:null,
        //   consultant_id: (consultantId)?consultantId:null,
        //   appointment_setter_id: (appointmentSetterId)?appointmentSetterId:null,
        //   image : image
        // }
      } else {
        path = "usertype/create";
        formData.append("phone", phone);
        formData.append("promo_code", promoCode);
        payload = formData;
        // {
        //   name: name,
        //   email: email,
        //   phone:phone,
        //   company_name: company_name,
        //   number_of_employees: number_of_employees,
        //   code: 'customer-admin',
        //   promo_code : promoCode,
        //   payment_status: paymentStatus,
        //   staffs:staffArray,
        //   price:price,
        //   sales_id : (salesId)?salesId:null,
        //   consultant_id: (consultantId)?consultantId:null,
        //   appointment_setter_id: (appointmentSetterId)?appointmentSetterId:null,
        //   image : image
        // }
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message);
        navigate("/subscriptions");
        setIsManage(false);
      } else {
        toastr.error(res.message);
        setIsManage(false);
      }
    } catch (err) {
      console.log(err.messege);
      setIsManage(false);
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("new_account_creation")}</div>
      </div>
      <form>
        <div className="relative flex flex-col-reverse sm:grid grid-cols-4 gap-4">
          <div className="col-span-3">
            <div className="relative bg-white rounded shadow divide-y divide-slate-200">
              <div className="py-4 px-4 sm:py-8 sm:px-6 flex flex-col">
                <div className="space-y-4 mt-4">
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div className="relative w-full">
                      <Input label={t("company_name")} requiredDisplay={true} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("company_name")} inputValue={company_name} setInput={setCompanyName} />
                    </div>
                    <div className="relative w-full">
                      <Input label={t("company_administrator_name")} requiredDisplay={true} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("enter_company_administrator_name")} inputValue={name} setInput={setName} />
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 gap-4">
                    <div className="relative w-full">
                      <Input label={t("administrator_email")} requiredDisplay={true} labelClasses={"!text-xs"} inputType={"email"} inputPlaceholder={t("enter_administrator_email")} inputValue={email} setInput={setEmail} />
                    </div>
                    <div className="relative w-full">
                      <Input label={t("administrator_phone_number") + ":"} labelClasses={"!text-xs"} inputType={"input"} inputPlaceholder={t("enter_administrator_phone_number") + ":"} inputValue={phone} setInput={setPhone} />
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-3 gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("number_of_employees")}
                        requiredDisplay={true}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={number_of_employees}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_employees"), value: "" },
                          ...plans.map((item) => ({
                            _id: item?._id,
                            label: item?.name,
                            value: item?.price,
                          })),
                        ]}
                        getSelectedValue={(e) => {
                          setNumberOfEmployees(e._id);
                          setPrice(e.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full">
                      <Input
                        label={t("price")}
                        requiredDisplay={true}
                        labelClasses={"!text-xs"}
                        inputType={"number"}
                        inputPlaceholder={t("enter_price")}
                        inputValue={price}
                        setInput={setPrice}
                        //isDisabled={true}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("payment_status")}
                        requiredDisplay={true}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={paymentStatus}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_payment_status"), value: "" },
                          { _id: 1, label: t("paid"), value: 1 },
                          { _id: 2, label: t("unpaid"), value: 2 },
                          { _id: 3, label: t("trial"), value: 3 },
                          // { _id: 4, label: t('inactive'),value:4},
                          // { _id: 5, label: t('sales_demo'),value:5}
                        ]}
                        getSelectedValue={(e) => setPaymentStatus(e._id)}
                      />
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-3 gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("account_type")}
                        requiredDisplay={true}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={accountType}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: "", label: t("select_account_type"), value: "" },
                          { _id: "business", label: capitalizeString("business"), value: "business" },
                          { _id: "demonstration", label: capitalizeString("demonstration"), value: "demonstration" },
                          { _id: "consultant", label: capitalizeString("consultant"), value: "consultant" },
                          { _id: "developer", label: capitalizeString("developer"), value: "developer" },
                          { _id: "training", label: capitalizeString("training"), value: "training" },
                        ]}
                        dropdownOptionBold={true}
                        getSelectedValue={(e) => setAccountType(e._id)}
                      />
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="relative w-full">
                      <Select
                        label={t("sales")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={salesId}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_sales"), value: "" },
                          ...sales.map((item) => {
                            let sales = item?.commission.filter((itm) => itm.code === "sales-staff");
                            return {
                              _id: item?._id,
                              label: item?.last_name + "," + item?.first_name + ", " + t("salesperson") + " " + (sales[0].commission ? sales[0].commission : 0) + "%",
                              value: sales[0].commission ? sales[0].commission : 0,
                            };
                          }),
                          { _id: null, label: t("none"), value: null },
                        ]}
                        getSelectedValue={(e) => {
                          setSalesId(e._id);
                          setSalesCommission(e.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("consultant")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={consultantId}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_consultant"), value: "" },
                          ...consultants.map((item) => {
                            let consultant = item?.commission.filter((itm) => itm.code === "consultant");
                            return {
                              _id: item?._id,
                              label: item?.last_name + "," + item?.first_name + ", " + t("consultant") + " " + (consultant[0].commission ? consultant[0].commission : 0) + "%",
                              value: consultant[0].commission ? consultant[0].commission : 0,
                            };
                          }),
                          { _id: null, label: t("none"), value: null },
                        ]}
                        getSelectedValue={(e) => {
                          setConsultantId(e._id);
                          setConsultantCommission(e.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("appointment_setter")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={appointmentSetterId}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_appointment_setter"), value: "" },
                          ...appointmentSetter.map((item) => {
                            let appointmentSetter = item?.commission.filter((itm) => itm.code === "appointment-setter");
                            return {
                              _id: item?._id,
                              label: item?.last_name + "," + item?.first_name + ", " + t("appt_setter") + " " + (appointmentSetter[0].commission ? appointmentSetter[0].commission : 0) + "%",
                              value: appointmentSetter[0].commission ? appointmentSetter[0].commission : 0,
                            };
                          }),
                          { _id: null, label: t("none"), value: null },
                        ]}
                        getSelectedValue={(e) => {
                          setAppointmentSetterId(e._id);
                          setAppointmentSetterCommission(e.value);
                        }}
                      />
                    </div>
                    <div className="relative w-full">
                      <Select
                        label={t("sales_admin")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={salesAdminId}
                        dropdownClass={"!w-60"}
                        dropdownData={[
                          { _id: " ", label: t("select_sales_admin"), value: "" },
                          ...salesAdmin.map((item) => {
                            let salesAdmin = item?.commission.filter((itm) => itm.code === "sales-admin");
                            return {
                              _id: item?._id,
                              label: item?.last_name + "," + item?.first_name + ", " + t("sales_admin") + " " + (salesAdmin[0].commission ? salesAdmin[0].commission : 0) + "%",
                              value: salesAdmin[0].commission ? salesAdmin[0].commission : 0,
                            };
                          }),
                          { _id: null, label: t("none"), value: null },
                        ]}
                        getSelectedValue={(e) => {
                          setSalesAdminId(e._id);
                          setSalesAdminCommission(e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-3 px-3 border-t border-slate-200">
                <div className="flex items-center justify-end gap-4">
                  <Button buttonClasses="" buttonLabelClasses="" buttonType={"button"} buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"} buttonIconPosition={"left"} buttonLabel={t("send")} functions={onSubmit} buttonHasLink={false} buttonDisabled={isManage} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 space-y-4">
            <div className="relative bg-white rounded shadow flex flex-col">
              <div className="py-6 px-6 flex flex-col space-y-4 relative h-full max-h-[calc(100% - 40px)]">
                <div className="text-sm font-medium text-slate-500 mb-1">{t("add_a_profile_picture_for_this_account_optional")}</div>
                <div className="w-full overflow-hidden rounded-md flex items-center h-full">
                  <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_image")} buttonClasses={"!h-full !w-full !absolute !opacity-0 !z-10 !top-0 !left-0"} functions={onImgChange} accepts={"image/*"} />
                  <Image src={showLogo} alt={"Admin"} width={"100%"} effect={"blur"} classes={"object-fit"} id={"image1"} />
                </div>
              </div>
              <div className="divide-x divide-slate-200 flex overflow-hidden rounded-b border-t border-slate-200">
                <ButtonFile buttonType={"button"} buttonIcon={"fa-light fa-image"} buttonIconPosition={"left"} buttonLabel={t("upload_image")} buttonClasses={"!bg-white !text-slate-500 rounded-none w-full hover:!bg-teal-500 hover:!text-white"} functions={onImgChange} accepts={"image/*"} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AccountCreation;
