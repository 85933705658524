import React,{useEffect,useState} from 'react';
import Table from "../../../elements/table/Table";
import { postData } from '../../../../services/api';
import { Tooltip } from 'react-tooltip';

import { useTranslation } from "react-i18next";

const FeaturedSection = () => {  
  const [planFeatures,setPlanFeatures] = useState([]);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const { i18n,t } = useTranslation();

  const convartString = (string) => {
    let wordsArray = string.split('-');
    let capitalizedWords = wordsArray.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    let resultString = capitalizedWords.join(' ');
    return resultString;
  }

  useEffect(() => {
    const loadPlanFeatures = async () => {
      setLoading(true);
      try {
        const result = await postData("plan-feature/list",{
          sortQuery:{'ordering':1},
          status:1
        });
        if(result.data) {
          const groupedData = result.data.reduce((acc, item) => {
            const key = item.headingType || "no-heading-type";
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {});         
        
          // Convert the grouped data into an array if needed
          const results = Object.keys(groupedData).map(key => ({
              headingType: key,
              items: groupedData[key]
          }));
          setPlanFeatures(results)
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPlanFeatures(); 
    const loadPlans = async () => {
      setLoading(true);
      try {
        const result = await postData("plans/list",{
          limit: 4,
          offset: 0,
          sortQuery:{'ordering':1},
          isView : 1
        });
        if(result.data) {
          setPlans(result.data)
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPlans();   
  },[])
  
  return (
    <section className="relative w-full bg-white">
      <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl  mx-auto">
        <div className="max-w-3xl w-full mx-auto text-center mb-3 md:mb-10  space-y-5">
          <h2 className="capitalize text-slate-950 text-2xl xs:text-3xl !leading-tight lg:text-4xl xl:text-5xl  font-extrabold font-PlusJakartaSans">Features</h2>
        </div>
        <div className="rounded-lg  w-full py-4 px-0 md:px-8">
          <div className="border border-slate-200 rounded-lg">
            <div className="relative overflow-x-auto">
              {
                (planFeatures && planFeatures.length>0) &&
                planFeatures.filter(_row => _row.headingType !== 'no-heading-type' && _row.headingType !== 'number-of-plans').map((item,index) => {
                  return (
                    <table className="w-full text-sm text-left" key={index}>
                      <thead className="text-xs text-gray-700 uppercase bg-white">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-slate-500 font-PlusJakartaSans text-lg whitespace-nowrap capitalize font-bold text-center">
                            {convartString(item?.headingType)}
                          </th>
                          {
                            plans.length>0 && plans.map((_it,_idx) => {
                              return (
                                <th scope="col" className="px-6 py-3 text-slate-500 font-PlusJakartaSans text-lg whitespace-nowrap capitalize font-bold text-center">
                                  {_it?.name}
                                </th>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          (item.items?.length >0) &&
                          item.items.map((it,ids) => {
                            let evenOdd = (ids % 2 === 0 ? 'even' : 'odd')
                            return (
                              <tr className={(evenOdd === 'even')?"bg-slate-100":"bg-transparent"}>
                                <td 
                                scope="row" 
                                className="px-6 py-3 text-slate-500 font-PlusJakartaSans text-sm whitespace-nowrap capitalize text-center relative group" 
                                data-tooltip-id={"info-tooltip-"+ids} 
                                data-tooltip-content={it?.short_description}
                                >{it?.name}
                                  {
                                    (it?.short_description) && (
                                      <Tooltip id={'info-tooltip-'+ids} place="top" style={{
                                        maxWidth: "400px",
                                        wordBreak: "break-word",
                                        overflowWrap: "break-word",
                                        whiteSpace: "normal",
                                        padding: "5px",
                                        backgroundColor: "#333",
                                        color: "#fff",
                                        borderRadius: "4px"
                                      }}></Tooltip>
                                    )
                                    // <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block px-2 py-1 bg-gray-700 text-white text-xs rounded tooltip">{it?.short_description}</span>
                                  }                                  
                                </td>
                                {
                                  plans.length>0 && plans.map((_it,_idx) => {                                    
                                    let featureRow =  _it?.features?.filter((val) => val?.code === it?.code);
                                    if(featureRow && featureRow.length>0){
                                      if(featureRow[0]?.fieldType && featureRow[0]?.fieldType === 'checkbox' && featureRow[0]?.checkboxValue){
                                        return (
                                          <td scope="row" className="px-6 py-3 text-dark-teal-400 font-PlusJakartaSans text-lg whitespace-nowrap capitalize text-center relative group"><i className="fa-light fa-circle-check"></i></td>
                                        )
                                      }else{
                                        return (
                                          <td scope="row" className="px-6 py-3 text-dark-teal-400 font-PlusJakartaSans text-lg whitespace-nowrap capitalize text-center relative group"></td>
                                        )
                                      }
                                    }
                                    
                                  })
                                }
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedSection;